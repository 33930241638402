/* ****************************************************************************************************

  # z-index
  - utility

**************************************************************************************************** */

@for $i from 0 through 10 {
  .u-z-index-#{$i} {
    z-index: #{$i} !important;
  }
}
