/* ======================================================================

  # card-interview
  - component

====================================================================== */
@use '../../foundation/tool/global';

.c-card-interview__item{
  padding: 2rem;
  border-radius: 1rem;
}

.c-card-interview__item.c-card-interview__item--withphoto{
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.6rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-card-interview__item.c-card-interview__item--withphoto {
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 2fr 3fr;
    -moz-column-gap: 2%;
    column-gap: 2%;
    align-items: stretch
  }
}

.c-card-interview__item + .c-card-interview__item{
  margin-top:3rem;
}

