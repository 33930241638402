/* ****************************************************************************************************

  # border
  - theme

**************************************************************************************************** */
@use '../../foundation/tool/global';

/* ======================================================================

  solid

====================================================================== */
.t-border {
  border: 1px solid global.$color-border;
}

/* ======================================================================

  dashed

====================================================================== */
.t-border-dashed {
  border: 1px dashed global.$color-border;
}
