/* ****************************************************************************************************

  # point
  - project

**************************************************************************************************** */
@use '../../foundation/tool/global';

.p-interview {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background:white;
  border-radius: 1rem 1rem 0 0;
}

.p-interview__head {
  position: relative;
}

.p-interview__img{
  border-radius: 1rem 1rem 0 0;
}

.p-interview__head-obi1{
  background: global.$color-pink;
  position: absolute;
  bottom:2.3rem;
  left:0;
  padding: 0.2rem 1.2rem;
  p{
    color:white;
  }
}
.p-interview__head-obi2{
  background: global.$color-dark-blue;
  position: absolute;
  bottom:-1rem;
  left:0;
  padding: 0.2rem 1.2rem;
  p{
    color:white;
  }
}
.p-interview__head-obi2.p-interview__head-obi2--pink{
  background: global.$color-pink;
}
.p-interview__head-obi2.p-interview__head-obi2--green{
  background: global.$color-green;
}
.p-interview__head-obi2.p-interview__head-obi2--yellow{
  background: global.$color-yellow;
}
.p-interview__head-obi1.p-interview__head-obi1--orange{
  background: global.$color-orange;
}
.p-interview__head-obi2.p-interview__head-obi2--orange{
  background: global.$color-orange;
}
@media print,
screen and (max-width:767px) {
  .p-interview__head-obi1.p-interview__head-obi1--orange{
    font-size: 1.2rem;
  }
  .p-interview__head-obi2.p-interview__head-obi2--orange{
    font-size: 1.2rem;
  }
}

.p-interview__body {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1.8rem;
  padding-right: 7%;
  padding-left: 7%
}
@media print,
screen and (min-width:768px) {
  .p-interview__body {
    padding-right: 7%;
    padding-left: 7%;
    padding-top: 1.8rem;
    padding-bottom: 2.2rem;
    flex: 1
  }
}
.p-interview__inner::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-right: .9rem solid global.$color-dark-blue;
  border-bottom: .9rem solid global.$color-dark-blue;
  border-left: .9rem solid transparent;
  border-top: .9rem solid transparent
}
.p-interview__inner--orange::after {
  border-right: .9rem solid global.$color-orange;
  border-bottom: .9rem solid global.$color-orange;
  border-left: .9rem solid transparent;
  border-top: .9rem solid transparent
}
.p-interview__body.p-interview__body--green {
  background-color: #fafdf9
}



@media print,
screen and (min-width:768px) {
  .p-interview__number span.strong {
    font-size: 2.2rem;
    margin-left: .8rem
  }
}
.p-interview__sub-title {
  line-height: 1.5;
  font-weight: 700;
  margin-top: .9rem
}
@media print,
screen and (min-width:768px) {
  .p-interview__sub-title {
    margin-top: 1.1rem;
    line-height: 1.4
  }
}
.p-interview__title {
  font-size: 1.8rem;
  line-height: 1.5;
  font-weight: 700;
  color: #333;
  margin-top: .9rem
}
@media print,
screen and (min-width:768px) {
  .p-interview__title {
    font-size: 2rem
  }
}
.p-interview__desc {
  line-height: 1.5;
  margin-top: .8rem;
  color: #333;
  font-weight: normal;
  font-size: 1.4rem;
  letter-spacing: 0.05em;
}
@media print,
screen and (min-width:768px) {
  .p-interview__desc {
    margin-top: 1.2rem;
    font-size:1.5rem;
  }
}