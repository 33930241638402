/* ****************************************************************************************************

  # margin
  - utility

**************************************************************************************************** */
@use '../../foundation/tool/global';
/* ======================================================================

  上方向のマージン

====================================================================== */
@for $i from 0 through 10 {
  .u-mt#{$i} {
    margin-top: 1rem * $i !important;
  }
}
@for $i from 0 through 10 {
  @media print, screen and (min-width: global.$breakpoint-min-md) {
    .u-mt-md#{$i} {
      margin-top: 1rem * $i !important;
    }
  }
}

/* ======================================================================

  右方向のマージン

====================================================================== */
@for $i from 0 through 10 {
  .u-mr#{$i} {
    margin-right: 1rem * $i !important;
  }
}

/* ======================================================================

  下方向のマージン

====================================================================== */
@for $i from 0 through 10 {
  .u-mb#{$i} {
    margin-bottom: 1rem * $i !important;
  }
}

/* ======================================================================

  左方向のマージン

====================================================================== */
@for $i from 0 through 10 {
  .u-ml#{$i} {
    margin-left: 1rem * $i !important;
  }
}
