/* ****************************************************************************************************

  # front-vision
  - project

**************************************************************************************************** */
@use '../../foundation/tool/global';

.p-front-vision{
  margin-top:36px;
  padding-bottom:36px;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-front-vision{
    margin-top:0;
  } 
}
.p-front-vision__cont.is-inView{
  animation-delay:.4s
}
.p-front-vision__desc{
  font-feature-settings:normal;
  font-size:1.5rem;
  letter-spacing:-.01em;
  line-height:2;
  transition-delay:unset;
  text-align: center;
}
.p-front-vision__desc p:after,.p-front-vision__desc p:before{
  content:"";
  display:block;
  height:0;
  width:0
}
.p-front-vision__desc p:before{
  margin-top:-.5em
}
.p-front-vision__desc p:after{
  margin-bottom:-.5em
}
.p-front-vision__desc p+p{
  margin-top:25px
}
.p-front-vision__more{
  padding-top:28px
}

@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-front-vision{
      // margin-top:240px;
      // margin-bottom:100px;
      text-align: center;
  }
  .p-front-vision__desc{
      font-size:1.6rem;
      line-height:1.9;
  }
  .p-front-vision__desc p:after,.p-front-vision__desc p:before{
      content:"";
      display:block;
      height:0;
      width:0
  }
  .p-front-vision__desc p:before{
      margin-top:-.45em
  }
  .p-front-vision__desc p:after{
      margin-bottom:-.45em
  }
  .p-front-vision__desc p+p{
      margin-top:35px
  }
  .p-front-vision__more{
      padding-top:37px
  }
}