/* ****************************************************************************************************

  # subpage
  - project

**************************************************************************************************** */
@use '../../foundation/tool/global';

.p-subpage__headTitle{
    .c-sect-mask-title,.c-sect-mask-title__text{
        text-align: left;
    }
    .c-sect-mask-title__textLayerB{
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%) translateX(0%);
    }
}

.p-subpage__head,.p-subpage__inner{
    position:relative;
    width:100%
}
.p-subpage__sentence+.p-subpage__info{
    margin-top:17.8666666667vw
}
.p-subpage-sentence__contHead{
    font-family:游ゴシック体,YuGothic,游ゴシック,Yu Gothic,Hiragino Kaku Gothic Pro,Meiryo,Noto Sans JP,sans-serif;
    font-size:2rem;
    font-weight:700;
    letter-spacing:-.01em;
    line-height:1.7;
    margin-bottom: 2rem;
}
.p-subpage-sentence__contHead:after,.p-subpage-sentence__contHead:before{
    content:"";
    display:block;
    height:0;
    width:0
}
.p-subpage-sentence__contHead:before{
    margin-top:-.35em
}
.p-subpage-sentence__contHead:after{
    margin-bottom:-.35em
}
.p-subpage-sentence__contNav{
    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    margin-top:33px
}
.p-subpage-sentence__contNavItem+.p-subpage-sentence__contNavItem{
    margin-top:16px
}
.p-subpage-sentence__contVis{
    margin-left:-8vw;
    margin-right:-8vw;
    margin-top:2rem;
    overflow:hidden;
    position:relative
}
.p-subpage-sentence__contVis:before{
    content:"";
    float:left;
    padding-top:56.1497326203%
}
.p-subpage-sentence__contVis:after{
    clear:both;
    content:"";
    display:table
}
.p-subpage-sentence__contVis>:first-child{
    height:100%;
    left:0;
    position:absolute;
    top:0;
    width:100%
}
.p-subpage-sentence__contVisInner{
    height:100%;
    position:relative;
    width:100%
}
.p-subpage-sentence__contVisPic{
    border-radius: 0 1rem 0 1rem;
    img{
        width:100%;
        border-radius: 0 1rem 0 1rem;
    }   
}

@media print, screen and (min-width: global.$breakpoint-min-md) {
    .p-subpage__sentence+.p-subpage__info{
        margin-top:6vw
    }
    .p-subpage__jobSeekers{
        padding-bottom:7.6134699854vw;
        padding-top:6vw
    }
    .p-subpage__jobSeekers+.p-subpage__humanResources{
        padding-top:6vw
    }
    .p-subpage__humanResources{
        padding-bottom:7.7598828697vw;
        padding-top:6vw
    }
    .p-subpage-sentence__contHead{
        font-size:2.4rem;
        line-height:1.6;
    }
    .p-subpage-sentence__contHead:after,.p-subpage-sentence__contHead:before{
        content:"";
        display:block;
        height:0;
        width:0
    }
    .p-subpage-sentence__contHead:before{
        margin-top:-.41666em
    }
    .p-subpage-sentence__contHead:after{
        margin-bottom:-.41666em
    }
    .p-subpage-sentence__contNav{
        flex-direction:row;
        margin-top:54px
    }
    .p-subpage-sentence__contNavItem+.p-subpage-sentence__contNavItem{
        margin-left:21px;
        margin-top:0
    }
    .p-subpage-sentence__contVis{
        margin-left:0;
        margin-right:0;
        margin-top:4.39238653vw
    }
    .p-subpage-sentence__contVis:before{
        content:"";
        float:left;
        padding-top:56.1904761905%
    }
    .p-subpage-sentence__contVis:after{
        clear:both;
        content:"";
        display:table
    }
    .p-subpage-sentence__contVis>:first-child{
        height:100%;
        left:0;
        position:absolute;
        top:0;
        width:100%
    }
    .p-subpage-sentence__contVisPic{
        border-radius: 0 1rem 0 1rem;
    }
}


/* ======================================================================

  ## インタビューのところ2カラム

====================================================================== */

.p-subpage-sentence__cont.p-subpage-sentence__cont-2col{
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 3rem;
    margin-top:2rem;
    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2rem;
    }
}

@media print, screen and (min-width: global.$breakpoint-min-md) {
    .p-subpage-sentence__cont.p-subpage-sentence__cont-2col{
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;
        grid-template-columns: 3fr 2fr;
        -moz-column-gap: 2rem;
        column-gap: 2rem;
        align-items: stretch;
    }
}
