/* ****************************************************************************************************

  # animation
  - foundation > variable > base

**************************************************************************************************** */

$opacity: .7 !default;
$transition-duration: .3s !default;
$transition-timing-function: ease !default;
