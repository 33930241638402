/* ****************************************************************************************************

  # row
  - component
  - bootstrap参考

**************************************************************************************************** */
@use '../../foundation/tool/global';
@use 'sass:math';

$col-gutter: 1.5rem;
$col-gutter-mq-min-md: 3%;

.c-row {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
}

.c-row.c-row--type_indivisible {
  justify-content: space-between;
}

/* ======================================================================

  col

====================================================================== */
.c-row__col {
  width: 100%;
}
@media print, screen and (max-width: global.$breakpoint-max-md) {
  .c-row__col + .c-row__col {
    margin-top: $col-gutter;
  }
}

/* タブレットからカラム化
---------------------------------------------------------------------- */
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-row__col.c-row__col--m_3-4 {
    width: calc(75% - math.div($col-gutter-mq-min-md, 2));
    margin-bottom: $col-gutter-mq-min-md;
    margin-left: $col-gutter-mq-min-md;
  }

  .c-row > .c-row__col.c-row__col--m_3-4:nth-child(2n + 1) {
    margin-left: 0;
  }

  .c-row > .c-row__col.c-row__col--m_3-4:nth-child(2n + 1):nth-last-child(-n + 2),
  .c-row
    > .c-row__col.c-row__col--m_3-4:nth-child(2n + 1):nth-last-child(-n + 2)
    ~ .c-row__col.c-row__col--m_3-4 {
    margin-bottom: 0;
  }
}

@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-row__col.c-row__col--m_2 {
    width: calc(50% - math.div($col-gutter-mq-min-md, 2));
    margin-bottom: $col-gutter-mq-min-md;
    margin-left: $col-gutter-mq-min-md;
  }

  .c-row > .c-row__col.c-row__col--m_2:nth-child(2n + 1) {
    margin-left: 0;
  }

  .c-row > .c-row__col.c-row__col--m_2:nth-child(2n + 1):nth-last-child(-n + 2),
  .c-row
    > .c-row__col.c-row__col--m_2:nth-child(2n + 1):nth-last-child(-n + 2)
    ~ .c-row__col.c-row__col--m_2 {
    margin-bottom: 0;
  }
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-row__col.c-row__col--m_3 {
    width: calc(33.333333% - math.div($col-gutter-mq-min-md, 1.5));
    margin-bottom: $col-gutter-mq-min-md;
    margin-left: $col-gutter-mq-min-md;
  }

  _:-ms-lang(x),
  .c-row__col.c-row__col--m_3 {
    width: calc(33.333333% - math.div($col-gutter-mq-min-md, 1.5) - .01rem);
  }

  .c-row > .c-row__col.c-row__col--m_3:nth-child(3n + 1) {
    margin-left: 0;
  }

  .c-row > .c-row__col.c-row__col--m_3:nth-child(3n + 1):nth-last-child(-n + 3),
  .c-row
    > .c-row__col.c-row__col--m_3:nth-child(3n + 1):nth-last-child(-n + 3)
    ~ .c-row__col.c-row__col--m_3 {
    margin-bottom: 0;
  }
}

@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-row.c-row--type_indivisible .c-row__col {
    margin-left: 0 !important;
  }
}

@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-row__col.c-row__col--m_4 {
    width: calc(25% - math.div($col-gutter-mq-min-md, 1.333));
    margin-bottom: $col-gutter-mq-min-md;
    margin-left: $col-gutter-mq-min-md;
  }

  _:-ms-lang(x),
  .c-row__col.c-row__col--m_4 {
    width: calc(25% - math.div($col-gutter-mq-min-md, 1.333) - .01rem);
  }

  .c-row > .c-row__col.c-row__col--m_4:nth-child(4n + 1) {
    margin-left: 0;
  }

  .c-row > .c-row__col.c-row__col--m_4:nth-child(4n + 1):nth-last-child(-n + 4),
  .c-row
    > .c-row__col.c-row__col--m_4:nth-child(4n + 1):nth-last-child(-n + 4)
    ~ .c-row__col.c-row__col--m_4 {
    margin-bottom: 0;
  }
}
