/* ****************************************************************************************************

  # color
  - theme

**************************************************************************************************** */
@use '../../foundation/tool/global';

.t-color-white {
  color: global.$color-white !important;
}

.t-color-black {
  color: global.$color-txt !important;
}

.t-color-red {
  color: global.$color-red !important;
}

.t-color-blue {
  color: global.$color-dark-blue !important;
}

.t-color-txt-with-highlight {
  text-decoration: underline;
  text-decoration-color: yellow;
  text-decoration-style: double; 
}

.color-blue-little-dark{
  color: global.$color-little-dark-blue;
}