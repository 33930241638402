/* ======================================================================

  # notice-list
  - component

====================================================================== */
@use '../../foundation/tool/global';

$notice-list-font-size: global.$font-size;
$notice-list-font-size-mq-min-md: (global.$font-size-mq-min-md - .1);
$notice-list-bullet-gap: .2em;
$notice-list-offset: (1em + $notice-list-bullet-gap);
$notice-list-color: global.$color-txt;
$notice-list-line-spacing: 1.4;
$notice-list-gutter: .2em;
$notice-list-gap: 1.4rem;
$notice-list-gap-mq-min-md: 1.4rem;
$notice-list-gap-sm: 1rem;
$notice-list-gap-sm-mq-min-md: 1rem;
$notice-list-gap-lg: 2rem;
$notice-list-gap-lg-mq-min-md: 2rem;

.c-notice-list {
  margin-top: $notice-list-gap;
  font-size: $notice-list-font-size;
  line-height: $notice-list-line-spacing;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-notice-list {
    margin-top: $notice-list-gap-mq-min-md;
    font-size: $notice-list-font-size-mq-min-md;
  }
}

/* gap-top-s
---------------------------------------------------------------------- */
.c-notice-list.c-notice-list--gap_top-sm {
  margin-top: $notice-list-gap-sm;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-notice-list.c-notice-list--gap_top-sm {
    margin-top: $notice-list-gap-sm-mq-min-md;
  }
}

/* gap-top-l
---------------------------------------------------------------------- */
.c-notice-list.c-notice-list--gap_top-lg {
  margin-top: $notice-list-gap-lg;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-notice-list.c-notice-list--gap_top-lg {
    margin-top: $notice-list-gap-lg-mq-min-md;
  }
}

/* gap-top-0
---------------------------------------------------------------------- */
.c-notice-list.c-notice-list--gap_top-0 {
  margin-top: 0;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-notice-list.c-notice-list--gap_top-0 {
    margin-top: 0;
  }
}

/* ======================================================================

  item

====================================================================== */
.c-notice-list__item {
  position: relative;
  padding-left: $notice-list-offset;
  text-indent: -($notice-list-offset);
  list-style-type: none;

  &::before {
    display: inline;
    margin-right: $notice-list-bullet-gap;
    content: '※';
  }
}

.c-notice-list__item + .c-notice-list__item {
  margin-top: $notice-list-gutter;
}
