/* ****************************************************************************************************

  # fade-up
  - component

**************************************************************************************************** */
@use '../../foundation/tool/global';

.c-fade-up {
  position: relative;
  top: 2.2rem;
  opacity: 0;
  transition: opacity .5s cubic-bezier(.445, .05, .55, .95) 0s,
    top .7s cubic-bezier(.445, .05, .55, .95) .1s;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-fade-up {
    transition: opacity .5s cubic-bezier(.445, .05, .55, .95) 0s,
      top .7s cubic-bezier(.445, .05, .55, .95) .1s;
  }
}

.c-fade-up.in-view {
  top: 0;
  opacity: 1;
}
