/* ****************************************************************************************************

  # z-index
  - foundation > mixin

**************************************************************************************************** */
@use '../variable/layout';
@use 'sass:map';

@mixin z-index($key) {
  z-index: map.get(layout.$stacking-order, $key);
}
