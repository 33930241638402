/* ****************************************************************************************************

  # logo
  - component

**************************************************************************************************** */
// ```
// div.c-logo.p-logo.p-logo--type_header
//   a.c-logo__inner(href=`${_relativePath}`)
//     img.c-logo__item(src=`${_relativePath}assets/img/logo/logo.svg` alt="logo")
// ```

.c-logo {
  position: relative;
}

/* ======================================================================

  ## inner

====================================================================== */
.c-logo__inner {
  display: block;
  width: 100%;
  height: 100%;
}

/* ======================================================================

  ## item

====================================================================== */
.c-logo__item {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}
