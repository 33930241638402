/* ****************************************************************************************************

  # clip
  - utility

**************************************************************************************************** */

.u-clip {
  overflow: hidden !important;
}
