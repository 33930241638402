/* ======================================================================

  # accordion
  - component

====================================================================== */
@use '../../foundation/tool/global';

/* ======================================================================

  ## head

====================================================================== */
.c-accordion__head {
  position: relative;
  display: block;
  cursor: pointer;
}

/* ======================================================================

  ## body

====================================================================== */
.c-accordion__body {
  width: 100%;
  height: 0;
  padding: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: height 0s global.$ease-out-custom .25s,
    margin 0s global.$ease-out-custom .25s,
    padding 0s global.$ease-out-custom .25s,
    opacity .15s global.$transition-timing-function,
    visibility .15s global.$transition-timing-function;
}

/* ### is active
---------------------------------------------------------------------- */
.c-accordion.is-open > .c-accordion__body {
  height: 100%;
  overflow: visible;
  visibility: visible;
  opacity: 1;
  transition: height 0s global.$ease-out-custom,
    margin 0s global.$ease-out-custom, padding 0s global.$ease-out-custom,
    opacity .2s global.$transition-timing-function .15s,
    visibility .2s global.$transition-timing-function .15s;
}
