/* ======================================================================

  # accordion
  - component

====================================================================== */
@use '../../foundation/tool/global';

.c-sect-mask-title,.c-sect-mask-title__text{
  // display:inline-flex;
  // flex-direction:column
  text-align: center;
}
.c-sect-mask-title__text{
  font-family: 'Hind', sans-serif;
  font-size:3.8rem;
  
  font-weight:400;
  line-height:1.2;
  position:relative
}
.c-sect-mask-title__text--noWrap{
  white-space:nowrap
}
.c-sect-mask-title__textLayerA{
  color:#333;
  opacity:0;
  transform:translate3d(0,15px,0);
  transition:opacity .65s cubic-bezier(.24,.13,.26,1.01),transform .9s cubic-bezier(.24,.13,.26,1.01)
}
@supports (clip-path:polygon(0 0,0 0,0 0)){
  .c-sect-mask-title__textLayerA{
      color:global.$color-light-blue;
  }
  .c-sect-mask-title__textLayerA--white{
    color:global.$color-blue;
}
}
.c-sect-mask-title__textLayerB{
  display:none;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none
}
@supports (clip-path:polygon(0 0,0 0,0 0)){
  .c-sect-mask-title__textLayerB{
      clip-path:polygon(0 0,0 0,0 0);
      color:#333;
      display:block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      transition:clip-path .65s cubic-bezier(.24,.13,.26,1.01) .5s
  }
  .c-sect-mask-title__textLayerB.c-sect-mask-title__textLayerB--white{
    color: white;
    transition:clip-path .65s cubic-bezier(.24,.13,.26,1.01) .5s
}
}
.c-sect-mask-title__textBrPc{
  display:none
}
.c-sect-mask-title__desc{
  color:global.$color-dark-blue;
  font-family:游ゴシック体,YuGothic,游ゴシック,Yu Gothic,Hiragino Kaku Gothic Pro,Meiryo,Noto Sans JP,sans-serif;
  font-size:1.4rem;
  font-weight:700;
  letter-spacing:-.01em;
  line-height:1.6666666667;
  margin-top:.8vw;
  opacity:0;
  transform:translate3d(0,5px,0);
  transition:opacity .65s ease .7s,transform .65s ease .7s
}
.c-sect-mask-title__desc.c-sect-mask-title__desc--white{
  color:white;
}
.c-sect-mask-title.is-inView .c-sect-mask-title__textLayerA{
  opacity:1;
  transform:translateZ(0)
}
.c-sect-mask-title.is-inView .c-sect-mask-title__textLayerB{
  clip-path:polygon(0 0,200% 0,0 200%)
}
.c-sect-mask-title.is-inView .c-sect-mask-title__desc{
  opacity:1;
  transform:translateZ(0)
}

@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-sect-mask-title__text{
      font-size:4.2rem;
      line-height:1.05
  }
  .c-sect-mask-title__textBrPc{
      display:block
  }
  .c-sect-mask-title__desc{
      letter-spacing:.15em;
      margin-top:.1464128843vw
  }
}