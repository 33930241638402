/* ****************************************************************************************************

  # sentnece
  - component

**************************************************************************************************** */
@use '../../foundation/tool/global';

$sentence-gap: 1rem;
$sentence-gap-mq-min-md: 1.5rem;

.c-sentence {
  position: relative;
}

/* ======================================================================

  ## contHead

====================================================================== */
.c-sentence__contHead{
  font-family:游ゴシック体,YuGothic,游ゴシック,Yu Gothic,Hiragino Kaku Gothic Pro,Meiryo,Noto Sans JP,sans-serif;
  font-size:2rem;
  font-weight:700;
  letter-spacing:-.01em;
  line-height:1.7;
  margin-bottom: 2rem;
  position:relative;
  padding-left: 4rem;

  &::before{
    content:'';
    display: block;
    position: absolute;
    top:0.6rem;
    left:0;
    width:3rem;
    height: 3rem;
    background:url(../img/common/icon-arrow-point.svg);
  }
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-sentence__contHead{
    font-size:2.4rem;
    line-height:1.8;
  }
}


/* ======================================================================

  ## question

====================================================================== */
.c-sentence__questionHead{
  font-family:游ゴシック体,YuGothic,游ゴシック,Yu Gothic,Hiragino Kaku Gothic Pro,Meiryo,Noto Sans JP,sans-serif;
  font-size:2rem;
  font-weight:700;
  letter-spacing:-.01em;
  line-height:1.7;
  margin-bottom: 2rem;
  position:relative;
  padding-left: 4rem;

  &::before{
    content:'';
    display: block;
    position: absolute;
    top:0.6rem;
    left:0;
    width:3rem;
    height: 3rem;
    background:url(../img/common/icon-question.svg);
  }
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-sentence__questionHead{
    font-size:2.4rem;
    line-height:1.8;
  }
}


/* ======================================================================

  ## item

====================================================================== */
.c-sentence__item {
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  & + & {
    margin-top: $sentence-gap;
  }
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-sentence__item {
    font-size: 1.6rem;
    color: global.$color-txt;
    & + & {
      margin-top: $sentence-gap-mq-min-md;
    }
  }
}

/* ======================================================================

  ## kakkoHead

====================================================================== */
.c-sentence__kakkoHead{
  padding: 30px;
	position: relative;
  margin:auto;
  width:30rem;
  margin-bottom:3rem;
  border-bottom:1px dashed white;

  &::before {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    border-left: solid 2px white;
    border-top: solid 2px white;
    top: 0;
    left: 0;
  }

  &::after {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    border-right: solid 2px white;
    border-top: solid 2px white;
    top: 0;
    right: 0;
  }
}

/* ======================================================================

  ## kakkoHead

====================================================================== */
.c-sentence__gkptitle{
  font-size: 6rem;
  letter-spacing: 0.4rem;
}

/* ======================================================================

  ## subtitle

====================================================================== */
.c-sentence__sub-title {
  font-size: 1.8rem;
  line-height: 1.5;
  font-weight: 700;
  color: #333;
  margin-top: 2.2rem;
  position: relative;
  margin-bottom: 1.4rem;
}
@media print,screen and (min-width:768px) {
  .c-sentence__sub-title {
    font-size: 2rem
  }
}

.c-sentence__sub-title.c-sentence__sub-title--number{
  padding-left:4.5rem;
}

/* ======================================================================

  ## mini-title

====================================================================== */
.c-sentence__mini-title{
  font-family:游ゴシック体,YuGothic,游ゴシック,Yu Gothic,Hiragino Kaku Gothic Pro,Meiryo,Noto Sans JP,sans-serif;
  font-size:1.8rem;
  font-weight:700;
  letter-spacing:-.01em;
  line-height:1.7;
  margin-bottom: 2rem;
  position:relative;
  color:global.$color-dark-blue;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-sentence__mini-title{
    font-size:2rem;
    line-height:1.8;
  }
}

/* ======================================================================

  ## number

====================================================================== */
.c-sentence__number{
  counter-increment: my-counter;
  background-color: global.$color-blue;
  border: 1px solid;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  color: #ffffff;
  font-size: 85%;
  line-height: 1;
  position:absolute;
  top:-0.4rem;
  left:0;
}

.c-sentence__number--yellow{
  background-color: global.$color-yellow;
}

.c-sentence__number--blue{
  background-color: global.$color-blue;
}

.c-sentence__number--green{
  background-color: global.$color-green;
}

.c-sentence__number--pink{
  background-color: global.$color-pink;
}


/* ======================================================================

  ## mini-icon

====================================================================== */

.c-sentence__mini-icon{
  span{
    img {
      width: 20px; /* アイコンの幅を調整 */
      margin-right: 10px; /* アイコンとテキストの間隔を調整 */
    }
  }
}

