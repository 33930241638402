/* ****************************************************************************************************

  # offer
  - project

**************************************************************************************************** */
@use '../../foundation/tool/global';


.p-offer__list {
  background-color: white;
  padding:2.5rem;
  margin-top:4rem;
  position: relative;
  border-radius: 0 1rem 0 1rem;
  &::before{
    content: "";
    top: 0;
    left: 0;
    border-bottom: 3rem solid transparent;
    border-left: 3rem solid global.$color-blue; /* ラベルの色はここで変更 */
    position: absolute;
    z-index: 100;
  }
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-offer__list {
    width: 100%;
  }
}



.p-offer__divider + .p-offer__divider{
  margin-top:2rem;
}

/* ======================================================================

  ## head

====================================================================== */
.p-offer__head {
  margin-bottom: 2rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-offer__head {
    width:25%;
    text-align: center;
  }
}

/* ======================================================================

  ## img

====================================================================== */
.p-offer__img{
  border-radius: 50%;
  border:2px solid global.$color-blue;
  padding:1rem;
  >img{
    border-radius: 50%;
  }
}

.p-offer__img--full{
  width:100%;
  img{
    width:100%;
  }
}

/* ======================================================================

  ## offer__position

====================================================================== */
.p-offer__position{
  color:global.$color-dark-blue;
  font-size: global.$font-size-md;
  margin-top:3rem;
}

/* ======================================================================

  ## offer__name

====================================================================== */
.p-offer__name{
  font-size: global.$font-size-lg;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-offer__name{
    font-size: global.$font-size-lg-mq-min-md;
  }
}

/* ======================================================================

  ## body

====================================================================== */
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-offer__body {
    width:70%;
  }
}

/* ======================================================================

  ## txt

====================================================================== */
.p-offer__txt {
  font-size: global.$font-size-md;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-offer__txt {
    font-size: global.$font-size-md-mq-min-md;
  }
}

/* ======================================================================

  ## offer__title

====================================================================== */
.p-offer__title {
  font-size: global.$font-size-md;
  margin-top:2rem;
  padding-top:2rem;
  border-top:1px dashed global.$color-blue;
  color:global.$color-dark-blue;
  margin-bottom:1.4rem;
}

/* ======================================================================

  ## offer__title-solo

====================================================================== */
.p-offer__title-solo {
  font-size: global.$font-size-lg;
  color:global.$color-dark-blue;
  margin-bottom:1.4rem;
  text-align: center;
  margin: auto;
  margin-bottom:2rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-offer__title-solo {
    text-align: left;
  }
}

/* ======================================================================

  ## offer__member

====================================================================== */
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-offer__member{
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: 1fr;
  }
}

/* ======================================================================

  ## offer__member-head

====================================================================== */
.p-offer__member-head{
  margin-bottom: 2rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-offer__member-head{
    margin-bottom: 0;
  }
}

/* ======================================================================

  ## offer__member-body

====================================================================== */
.p-offer__member-body{
  padding-left: 0;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-offer__member-body{
    padding-left: 2rem;
  }
}

/* ======================================================================

  ## offer__list

====================================================================== */
.p-offer__list{
  li{
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px dashed global.$color-gray;
    position: relative;
    padding-left: 3rem;
    &::after{
      content: "";
      width: 2rem;
      height: 2rem;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      background-image: url(../img/common/icon-company.svg);
    }
  }
}
.p-offer__list.p-offer__list--human{
  li{
    &::after{
      background-image: url(../img/common/icon-human.svg);
    }
  }
}

