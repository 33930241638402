/* ****************************************************************************************************

  # dl-list
  category: project

**************************************************************************************************** */
@use '../../foundation/tool/global';


@media print, screen and (min-width: global.$breakpoint-min-md)  {
   dl.p-dl-list {
     display: grid;
     grid-template-columns: 150px auto
   }
 }
 .p-dl-list__dt {
   font-weight: 500;
   border-top: 2px solid global.$color-dark-blue;
   padding: 1.6rem 0 1.4rem 0
 }
@media print, screen and (min-width: global.$breakpoint-min-md)  {
   .p-dl-list__dt {
     padding: 2.5rem 0
   }
   .p-dl-list__dt:last-of-type {
     border-bottom: 2px solid global.$color-dark-blue
   }
 }
 .p-dl-list__dd {
   border-top: 2px solid #e1e1e1;
   padding: 1.4rem 0 1.6rem 0
 }
@media print, screen and (min-width: global.$breakpoint-min-md)  {
   .p-dl-list__dd {
     padding: 2.5rem 0 2.5rem 2rem
   }
 }
 .p-dl-list__dd:last-child {
   border-bottom: 2px solid global.$color-dark-blue
 }
@media print, screen and (min-width: global.$breakpoint-min-md)  {
   .p-dl-list__dd:last-child {
     border-bottom: 2px solid #e1e1e1
   }
 }