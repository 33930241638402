/* ****************************************************************************************************

  # kv-title
  - project

**************************************************************************************************** */
@use '../../foundation/tool/global';

.p-kv-title {
  text-align: center;
}

/* ======================================================================

  ## item

====================================================================== */
.p-kv-title__item {
  font-size: 3rem;
  color: global.$color-white;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-kv-title__item {
    font-size: 4rem;
  }
}
