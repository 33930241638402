/* ****************************************************************************************************

  # card
  - project

**************************************************************************************************** */
@use '../../foundation/tool/global';
// ```
// .p-card
//   .p-card__divider
//     .p-card__item
//       .p-card__head
//         .p-card__image
//           img(src="")
//       .p-card__body
//         .p-card__date
//         .p-card__title
//         .p-card__txt
//   .p-card__divider
// ```

.p-card {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

/* ======================================================================

  ## divider

====================================================================== */
.p-card__divider {
  width: 100%;
}
@media print, screen and (max-width: global.$breakpoint-max-md) {
  .p-card__divider + .p-card__divider {
    margin-top: 9%;
  }
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-card__divider {
    width: 31%;
    margin-bottom: 3.5%;
    margin-left: 3.5%;
  }

  .p-card > .p-card__divider:nth-child(3n + 1) {
    margin-left: 0;
  }

  .p-card > .p-card__divider:nth-child(3n + 1):nth-last-child(-n + 3),
  .p-card
    > .p-card__divider:nth-child(3n + 1):nth-last-child(-n + 3)
    ~ .p-card__divider {
    margin-bottom: 0;
  }
}

/* ======================================================================

  ## item

====================================================================== */
.p-card__item {
  position: relative;
  display: block;
}

/* ======================================================================

  ## head

====================================================================== */
// .p-card__head {
// }

/* ======================================================================

  ## image

====================================================================== */
.p-card__image {
  @include global.fitted-image(
    $ratioWidth: 800,
    $ratioHeight: 534,
    $objectFitCover: true
  );
}

/* ### img
---------------------------------------------------------------------- */
// .p-card__image > img {
//   display: block;
//   z-index: 1;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   font-family: "object-fit: cover;";
// }

/* ======================================================================

  ## body

====================================================================== */

.p-card__body {
  position: relative;
  background-color: global.$color-light-blue;
  padding-top: 1rem;
  padding-bottom: 1.8rem;
  padding-right: 7%;
  padding-left: 7%
}

.p-card__head + .p-card__body {
  padding-top: 1rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-card__body {
    padding-right: .8rem;
    padding-left: .8rem;
  }

  .p-card__head + .p-card__body {
    padding-top: 1.8rem;
  }
}

/* ======================================================================

  ## date

====================================================================== */
.p-card__date {
  position: relative;
  font-size: global.$font-size-sm;
  line-height: 1.4;
  color: global.$color-txt;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-card__date {
    font-size: global.$font-size-sm-mq-min-md;
  }
}

/* ======================================================================

  ## title

====================================================================== */
.p-card__title {
  font-size: global.$font-size-md;
  font-weight: global.$font-weight-bold;
  color: global.$color-txt;
}

.p-card__date + .p-card__title {
  margin-top: .5rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-card__title {
    font-size: global.$font-size-md-mq-min-md;
  }
}

/* ======================================================================

  ## txt

====================================================================== */
.p-card__txt {
  font-size: global.$font-size;
  line-height: 1.5;
  color: global.$color-txt;
}

.p-card__title + .p-card__txt {
  margin-top: .8rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-card__txt {
    font-size: global.$font-size-mq-min-md;
  }

  .p-card__title + .p-card__txt {
    margin-top: .9rem;
  }
}

/* ======================================================================

  ## number

====================================================================== */
.p-card__number {
  font-size: global.$font-size;
  line-height: 1.5;
  color: global.$color-txt;
}

@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-card__number {
    font-size: global.$font-size-mq-min-md;
  }
}

/* ****************************************************************************************************

  # card-ver2
  - project

**************************************************************************************************** */

// ```
// .p-card-ver2
//   .p-card-ver2__divider
//     .p-card-ver2__item
//       .p-card-ver2__head
//         .p-card-ver2__image
//           img(src="")
//       .p-card-ver2__body
//         .p-card-ver2__date
//         .p-card-ver2__title
//         .p-card-ver2__txt
//   .p-card-ver2__divider
// ```

.p-card-ver2 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

/* ======================================================================

  ## divider

====================================================================== */
.p-card-ver2__divider {
  width: 100%;
}

@media print, screen and (max-width: global.$breakpoint-min-md) {
  .p-card-ver2__divider + .p-card-ver2__divider{
    margin-top:3rem;
  }
}

/* For 2n version */
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-card-ver2__divider {
    width: 48%; /* Adjust the width for a 2n grid */
    margin-bottom: 3.5%;
    margin-left: 3.5%;
  }

  .p-card-ver2 > .p-card-ver2__divider:nth-child(2n + 1) {
    margin-left: 0;
  }

  .p-card-ver2 > .p-card-ver2__divider:nth-child(2n + 1):nth-last-child(-n + 2),
  .p-card-ver2
    > .p-card-ver2__divider:nth-child(2n + 1):nth-last-child(-n + 2)
    ~ .p-card-ver2__divider {
    margin-bottom: 0;
  }
}

/* ======================================================================

  ## item

====================================================================== */
.p-card-ver2__item {
  position: relative;
  display: block;
}

/* ======================================================================

  ## head

====================================================================== */
// .p-card-ver2__head {
// }

/* ======================================================================

  ## image

====================================================================== */
// .p-card-ver2__image {
//   @include global.fitted-image(
//     $ratioWidth: 800,
//     $ratioHeight: 534,
//     $objectFitCover: true
//   );
// }

/* ### img
---------------------------------------------------------------------- */
// .p-card-ver2__image > img {
//   display: block;
//   z-index: 1;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   font-family: "object-fit: cover;";
// }

/* ======================================================================

  ## body

====================================================================== */
.p-card-ver2__head + .p-card-ver2__body {
  padding-top: 1rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-card-ver2__body {
    padding-right: .8rem;
    padding-left: .8rem;
  }

  .p-card-ver2__head + .p-card-ver2__body {
    padding-top: 1.8rem;
  }
}

/* ======================================================================

  ## date

====================================================================== */
.p-card-ver2__date {
  position: relative;
  font-size: global.$font-size-sm;
  line-height: 1.4;
  color: global.$color-txt;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-card-ver2__date {
    font-size: global.$font-size-sm-mq-min-md;
  }
}

/* ======================================================================

  ## title

====================================================================== */
.p-card-ver2__title {
  font-size: global.$font-size-md;
  font-weight: global.$font-weight-bold;
  color: global.$color-txt;
}

.p-card-ver2__date + .p-card-ver2__title {
  margin-top: .5rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-card-ver2__title {
    font-size: global.$font-size-md-mq-min-md;
  }
}

/* ======================================================================

  ## txt

====================================================================== */
.p-card-ver2__txt {
  font-size: global.$font-size;
  line-height: 1.5;
  color: global.$color-txt;
}

.p-card-ver2__title + .p-card-ver2__txt {
  margin-top: .8rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-card-ver2__txt {
    font-size: global.$font-size-mq-min-md;
  }

  .p-card-ver2__title + .p-card-ver2__txt {
    margin-top: .9rem;
  }
}

