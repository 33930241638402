/* ****************************************************************************************************

  # title
  - project

**************************************************************************************************** */
@use '../../foundation/tool/global';

.p-title {
  margin-bottom: 2rem;
  font-size: global.$font-size-title;
  color: global.$color-black;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-title {
    margin-bottom: 2.4rem;
  }
}
