/* ****************************************************************************************************

  # heading
  - component

**************************************************************************************************** */
@use '../../foundation/tool/global';

.c-heading {
  @include global.font-size-heading();
}
