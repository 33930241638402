/* ****************************************************************************************************

  # bnr
  - project

**************************************************************************************************** */
@use '../../foundation/tool/global';
// ```
// .p-bnr
//   a.p-bnr__inner(href="")
//     img(src="")
// ```

.p-bnr {
  text-align: center;
}

/* ======================================================================

  ## inner

====================================================================== */
// .p-bnr__inner {
// }

/* ### img
---------------------------------------------------------------------- */
.p-bnr__inner img {
  max-width: 100%;
}
