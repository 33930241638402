/* ****************************************************************************************************

  # front-intro
  - project

**************************************************************************************************** */
@use '../../foundation/tool/global';


.p-front-intro__inner{
  margin:auto;
  // padding-left:8vw;
  // padding-right:8vw;
  position:relative;
  width:100%
}
.p-front-intro__keyVis{
  min-height:.1px;
  position:relative;
  width:100%;
  overflow: hidden;
}
.p-front-intro__cont{
  position:relative;
  z-index:10;
  margin-top:4rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-front-intro__cont{
    margin-top: 4rem;
  }
}
.p-front-intro__cont-inner{
  padding-left:8vw;
  padding-right:8vw;
}
.p-front-intro__keyVisMask{
  left:0;
  opacity:1;
  overflow:hidden;
  top:global.$header-height-mq-min-md;
  width:100%;
}

.p-front-intro__keyVisMask.is-show{
  opacity:1
}
.p-front-intro__keyVisMask.is-endPosi{
  margin-left:-8vw;
  position:absolute
}

.p-front-intro__keyVisMaskInner{
  margin-left:auto;
  margin-right:auto;
  overflow:hidden;
  position:relative;
  width:100%;
  z-index:1;
}
.p-front-intro__keyVisMaskInner:before{
  content:"";
  float:left;
}
.p-front-intro__keyVisMaskInner:after{
  clear:both;
  content:"";
  display:table
}
.p-front-intro__keyVisLogo{
  transition:opacity .6s;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 追加: アスペクト比を保ちつつ要素にフィット */
  object-position: center center; /* 追加: 中央に配置 */
  opacity: 1;
}
.p-front-intro__scrollArrow{
  height:100vh;
  left:0;
  position:absolute;
  right:0;
  top:0
}
.p-front-intro__scrollArrowCont{
  bottom:20px;
  display:block;
  height:16px;
  position:absolute;
  right:20px;
  transition:opacity .3s,visibility .3s;
  width:13px
}


@media print, screen and (min-width: global.$breakpoint-min-md) {
  // .p-front-intro__keyVis{
  //     height:80vh;
  // }
  // .p-front-intro__mainCont{
  //   margin-top:10%;
  //   padding:5rem 2rem 2rem;
  //   background:white;
  //   border-radius: 1rem 1rem 0 0;
  // }
}
// @media print, screen and (min-width: global.$breakpoint-min-md) {

//   .p-front-intro__inner{
//       display:flex;
//       // padding-left:2.196193265vw;
//       // padding-right:2.196193265vw
//   }
//   .p-front-intro__keyVis{
//       height:auto;
//       padding-right:3.5871156662vw;

//   }
//   .p-front-intro__cont{
//       padding-left:0;
//       padding-right:0;
//   }
//   .p-front-intro__cont-inner{
//     padding-left:0;
//     padding-right:0;
//   }
//   .p-front-intro__keyVisMask{
//       left:0;
//       margin-left:0;
//       height:100vh;
//   }
//   .p-front-intro__keyVisMaskInner{
//     margin-left: 0;
//     margin-right: auto;
//     overflow: hidden;
//     position: relative;
//     width: 64%;
//     z-index: 1;
//   }
//   .p-front-intro__keyVisMask video,.p-front-intro__keyVisMask video{
//     transform:scale(1)
//   }
//   .p-front-intro__keyVisMask.is-endPosi{
//       margin-left:-2.196193265vw
//   }
//   .p-front-intro__keyVis{
//       transition:opacity 0s
//   }
//   // .p-front-intro__keyVisLogo{
//   //     padding-top: calc(50vh - 60%);
//   // }
//   .p-front-intro__mainCont{
//       margin-top: 0;
//       // padding-top: calc(50vh - 13rem);
//       background:white;
//       padding-left:4vw;
//   }
//   .p-front-intro__scrollArrowCont{
//       bottom:34px;
//       height:19px;
//       right:7.906295754vw;
//       width:16px
//   }
//   @supports (clip-path:path("M0 11.5075V8.9383L7.02439 15.5517V0H8.97561V15.5517L16 8.88946V11.5563L8.04878 19L0 11.5075Z")){
//       .p-front-intro__scrollArrowCont{
//           clip-path:path("M0 11.5075V8.9383L7.02439 15.5517V0H8.97561V15.5517L16 8.88946V11.5563L8.04878 19L0 11.5075Z")
//       }
//       .p-front-intro__scrollArrowCont:before{
//         animation:arrowVerticalBack 2.2s ease-in-out infinite;
//         content:"";
//         display:block;
//         height:100%;
//         left:0;
//         position:absolute;
//         top:0;
//         width:100%;
//         z-index:1
//     }
//   }
//   .browsr-ie .p-front-intro__keyVisMask{
//       position:fixed;
//       top:auto
//   }
// }