/* ****************************************************************************************************

  # form
  - project

**************************************************************************************************** */
@use "../../foundation/tool/global";

.p-form {
  padding: 3rem 5vw;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-form {
    padding: 6rem 0;
  }
}
.p-form__tr {
  display: flex;
  flex-direction: column;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-form__tr {
    flex-direction: row;
  }
}
.p-form__term-td {
  width: 100%;
  border-top: 2px solid global.$color-blue;
  padding: 2rem 0;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-form__term-td {
    width: 25%;
    padding: 3rem 0;
  }
}
span.p-form__term-requirement {
  color: #fff;
  display: inline-block;
  background-color: global.$color-red;
  padding: 0 0.5rem;
  font-size: 1.2rem;
  font-weight: 700;
  margin-right: 1rem;
}
span.p-form__term-any {
  color: #fff;
  display: inline-block;
  background-color: global.$color-blue;
  padding: 0 0.5rem;
  font-size: 1.2rem;
  font-weight: 700;
  margin-right: 1rem;
}
span.p-form__term-title {
  font-weight: 500;
}
.p-form__answer-td {
  width: 100%;
  border-top: 2px solid #e1e1e1;
  padding: 2rem 0 2.3rem 0;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-form__answer-td {
    width: 75%;
    padding: 3rem 1.8rem;
  }
}
.p-form__tr:last-child .p-form__answer-td {
  border-bottom: 2px solid global.$color-blue;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-form__tr:last-child .p-form__answer-td {
    border-bottom: none;
  }
}
.p-form__answer-td.p-form__answer-td--name {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0.8rem;
  }
  @media print, screen and (min-width: global.$breakpoint-min-md) {
    .p-form__answer-td.p-form__answer-td--name {
      grid-template-columns: 1fr 1fr;
      -moz-column-gap: 5%;
      column-gap: 5%;
    }
  }
div.p-form__answer-td__name-divider {
  display: grid;
  grid-template-columns: 3.5rem 1fr;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  div.p-form__answer-td__name-divider {
    grid-template-columns: 4.5rem 1fr;
  }
}
.p-form__answer-td.p-form__answer-td--addr {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-form__answer-td.p-form__answer-td--addr span.p-form__answer-td__text {
    margin-right: 2rem;
  }
}
.p-form__answer-td__addr-divider {
  display: grid;
  grid-template-columns: 25% 75%;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-form__answer-td__addr-divider {
    grid-template-columns: 9rem 22rem;
  }
}
.p-form__answer-td__addr-divider:last-of-type {
  margin-top: 0.8rem;
  display: block;
}
input.p-form__input-text {
  background-color: rgba(240, 240, 240, 0.9411764706);
  font-size: 1.6rem;
  padding: 0.6rem 1rem;
  width: 100%;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  input.p-form__input-text {
    font-size: 1.8rem;
    padding: 0.8rem 1rem;
  }
}
input[type="radio"] + .radio-label {
  position: relative;
  padding-left: 3.2rem;
  cursor: pointer;
}
input[type="radio"] + .radio-label:before {
  transition: 0.3s;
  content: "";
  background: #fff;
  display: inline-block;
  border: 1px solid gray;
  width: 2rem;
  height: 2rem;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  cursor: pointer;
}
input[type="radio"]:checked + .radio-label:before {
  background-color: global.$color-blue;
}
.p-form__btn {
  width: 100%;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.p-form__btn::after {
  content: "";
  z-index: 1;
  display: block;
  position: absolute;
  background-color: global.$color-blue;
  width: 1rem;
  height: 1px;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-form__btn::after {
    width: 2rem;
  }
}
input.p-form__btn-input {
  cursor: pointer;
  display: block;
  width: 100%;
  background-color: #d2e8ef;
  border: 1px solid global.$color-blue;
  font-weight: 700;
  color: global.$color-blue;
  padding: 0.8rem 0.5rem;
  position: relative;
  transition: all 0.5s ease;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  input.p-form__btn-input {
    padding: 1rem 0.5rem;
  }
}
input.p-form__btn-input:hover {
  opacity: 0.7;
}
.p-form-error {
  margin: 2rem;
  color: global.$color-red;
}
.p-form-error__title {
  font-weight: 700;
}

.p-form__textarea{
  background-color: rgba(240, 240, 240, 0.9411764706);
  width: 100%;
  height: 100%;
  padding: 0.6rem 1rem;
}

.p-form__select {
  width: 100%;
  height: 40px;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 2px 0 white;
  border-radius: 2px;
  border: 1px solid #333;
  padding-left: 2rem;

  &__expand {
    width: 0;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;

    &::after {
      content: '\003E';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%) rotate(90deg) scaleY(1.75);
      color: #3e3e3e;
      font-size: 28px;
      pointer-events: none;
      z-index: 2;
      transition: all 250ms cubic-bezier(.4, .25, .3, 1);
      opacity: .6;
    }

    &:hover::after {
      opacity: 1;
    }

    &:checked::after {
      transform: translate(-50%, -50%) rotate(90deg) scaleX(-1) scaleY(1.75);
    }
  }

  &__expandLabel {
    display: block;
    width: 100%;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  &__close {
    display: none;
  }

  &__closeLabel {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
  }

  &__items {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #2fb5d1;
    border-radius: 2px;
    padding-top: 40px;
  }

  &__input {
    display: none;
  }

  &__label {
    transition: all 250ms cubic-bezier(.4, .25, .3, 1);
    display: block;
    height: 0;
    font-size: 1.2rem;
    line-height: 40px;
    overflow: hidden;
    color: #3e3e3e;
    background-color: #fff;
    cursor: pointer;
    padding-left: 20px;

    &-placeholder {
      height: 40px;
      vertical-align: middle;
      position: absolute;
      top: 0;
      left: 0;
      opacity: .6;
      background-color: transparent;
    }
  }

  &__expand:checked {
    + .p-form__select__closeLabel {
      display: block;

      + .p-form__select__options .p-form__select__label {
        height: 40px;

        &:hover {
          background-color: #f7f7f7;
        }

        + .p-form__select__expandLabel {
          display: none;
        }
      }
    }
  }

  &__input:checked + .p-form__select__label {
    height: 40px;
    margin-top: -40px;
  }
}
