/* ****************************************************************************************************

  # body
  - layout

**************************************************************************************************** */
@use '../foundation/tool/global';

.l-body {
  position: relative;
  padding: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: opacity .4s ease-in-out, visibility .4s ease-in-out;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .l-body {
    min-width: 1024px;
    // padding: $header-height-mq-min-md 0 0;
  }
}

/* is-show
---------------------------------------------------------------------- */
html.is-loaded .l-body {
  visibility: visible;
  opacity: 1;
}
