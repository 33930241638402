/* ****************************************************************************************************

  # placeholder
  - component

**************************************************************************************************** */

.c-placeholder {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}

/* ======================================================================

  spacer

====================================================================== */
.c-placeholder__spacer {
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;
  display: block;
  width: 100%;
  height: auto;
}

/* ======================================================================

  画像

====================================================================== */
.c-placeholder__image {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: block;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
}
