/* ****************************************************************************************************

  # highlight
  - theme

**************************************************************************************************** */

.t-highlight {
  display: inline;
  padding: 0 .1rem;
  background-image: linear-gradient(
    180deg,
    transparent 0%,
    transparent 52%,
    #fcd0df 52%,
    #fcd0df 100%
  );
}
