/* ****************************************************************************************************

  # point
  - project

**************************************************************************************************** */
@use '../../foundation/tool/global';

.p-point {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column
}
.p-point__body {
  position: relative;
  background-color: global.$color-light-blue;
  padding-top: 1rem;
  padding-bottom: 1.8rem;
  padding-right: 7%;
  padding-left: 7%
}
@media print,
screen and (min-width:768px) {
  .p-point__body {
    padding-right: 7%;
    padding-left: 7%;
    padding-top: 1.8rem;
    padding-bottom: 2.2rem;
    flex: 1
  }
}
.p-point__body::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-right: .9rem solid global.$color-blue;
  border-bottom: .9rem solid global.$color-blue;
  border-left: .9rem solid transparent;
  border-top: .9rem solid transparent
}
.p-point__body.p-point__body--green {
  background-color: #fafdf9
}
.p-point__number {
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: 700;
  color: global.$color-dark-blue
}
@media print,
screen and (min-width:768px) {
  .p-point__number {
    font-size: 1.4rem
  }
}
.p-point__number span.strong {
  font-size: 1.6rem;
  margin-left: .5rem
}
@media print,
screen and (min-width:768px) {
  .p-point__number span.strong {
    font-size: 2.2rem;
    margin-left: .8rem
  }
}
.p-point__sub-title {
  line-height: 1.5;
  font-weight: 700;
  margin-top: .9rem
}
@media print,
screen and (min-width:768px) {
  .p-point__sub-title {
    margin-top: 1.1rem;
    line-height: 1.4
  }
}
.p-point__title {
  font-size: 1.8rem;
  line-height: 1.5;
  font-weight: 700;
  color: #333;
  margin-top: .9rem
}
@media print,
screen and (min-width:768px) {
  .p-point__title {
    font-size: 2rem
  }
}
.p-point__desc {
  line-height: 1.5;
  margin-top: .8rem;
  color: #333
}
@media print,
screen and (min-width:768px) {
  .p-point__desc {
    margin-top: 1.2rem
  }
}