.swiper {
    /*スライダーの幅と高さを調整*/
    width: min(100%,100%);
    margin-left:4vw;
  }
  
  .swiper-slide {
    /*スライド要素の幅と高さを調整*/
    width: 100%;
    height: 100%;
  
    /*テキストの位置調整*/
    display: flex;
    justify-content: center;
    align-items: center;
  
    /*テキストの色と太さを指定*/
    color: #fff;
    font-weight: bold;
  }
  
  