/* ======================================================================

  # btn
  - component

====================================================================== */
@use '../../foundation/tool/global';

.c-btn{
  border-radius:42px;
  border:1px solid #92c8c7;
  color:white;
  display:inline-flex;
  font-family:jaf-bernina-sans,Hiragino Kaku Gothic Pro,Meiryo,Noto Sans JP,sans-serif;
  font-size:1.5rem;
  font-weight:600;
  height:55px;
  letter-spacing:.01em;
  line-height:1.3;
  position:relative;
  text-align:center;
  width:100%;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-btn{
    width:300px;
  }
}
.c-btn--sizeAuto{
  min-width:300px;
  width:auto
}
.c-btn.is-current .c-btn__inner{
  background-color:#333;
  color:#fff
}
.c-btn__inner{
  align-items:center;
  background-color:white;
  border-radius:42px;
  color:#333;
  display:inline-flex;
  height:100%;
  justify-content:center;
  overflow:hidden;
  position:relative;
  transition:color .2s .2s;
  width:100%;
  z-index:1
}
.c-btn__inner--sizeAuto{
  padding:0 42px
}
.c-btn__circle{
  left:24px;
  top:24px
}
.c-btn__circle,.c-btn__circle>span{
  display:inline-block;
  height:5px;
  position:absolute;
  width:5px
}
.c-btn__circle>span{
  background-color:#92c8c7;
  border-radius:50%;
  left:50%;
  top:50%;
  transform:translate3d(-50%,-50%,0);
  transition:width .35s cubic-bezier(.87,.24,.36,1),height .35s cubic-bezier(.87,.24,.36,1);
  z-index:1
}
.c-btn__txt{
  margin-bottom:-1px;
  max-height:100%;
  position:relative;
  z-index:2;
  margin-left: 1rem;
}

.c-btn__blank,.c-btn__dlArrow{
  display:inline-block;
  height:10px;
  margin-right:-10px;
  position:relative;
  transition:background .2s .2s;
  z-index:2
}


.c-btn:hover .c-btn__inner{
  color:#fff
}
.c-btn:hover .c-btn__circle>span{
  height:1600px;
  width:1600px
}


/* ======================================================================

  ## c-btn-contact

====================================================================== */

.c-btn-contact{
  height:15rem;
  width:100%;
  background:white;
  border-radius: 0 1rem 0 1rem;
  position: relative;
}
.c-btn-contact__inner{
  position: absolute;
  margin:auto;
  top:50%;
  left:50%;
  transform: translateY(-50%) translateX(-50%);
  text-align: center;
}
.c-btn-contact__title {
  color: #333;
  font-size: 1.8rem;
  font-weight: global.$font-weight-bold;
  margin-bottom:1rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-btn-contact__title {
    font-size: 2rem;
  }
}
.c-btn-contact__subtitle{
  color:global.$color-blue;
  font-size: global.$font-size-xs;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-btn-contact__subtitle{
    font-size: global.$font-size-xs-mq-min-md;
  }
}


/* ======================================================================

  ## c-btn-ver2

====================================================================== */

.c-btn-ver2 {
  border-radius: 42px;
  border: 1px solid global.$color-pink; /* 色を変更 */
  color: white;
  display: inline-flex;
  font-family: jaf-bernina-sans, Hiragino Kaku Gothic Pro, Meiryo, Noto Sans JP, sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  height: 55px;
  letter-spacing: 0.01em;
  line-height: 1.3;
  position: relative;
  text-align: center;
  width: 100%;
}

@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-btn-ver2 {
    width: 300px;
  }
}

.c-btn-ver2--sizeAuto {
  min-width: 300px;
  width: auto;
}

.c-btn-ver2.is-current .c-btn__inner {
  background-color: #333;
  color: #fff;
}

.c-btn-ver2__inner {
  align-items: center;
  background-color: white;
  border-radius: 42px;
  color: #333;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: color 0.2s 0.2s;
  width: 100%;
  z-index: 1;
}

.c-btn-ver2__inner--sizeAuto {
  padding: 0 42px;
}

.c-btn-ver2__circle {
  left: 24px;
  top: 24px;
}

.c-btn-ver2__circle,
.c-btn-ver2__circle>span {
  display: inline-block;
  height: 5px;
  position: absolute;
  width: 5px;
}

.c-btn-ver2__circle>span {
  background-color: global.$color-pink; /* 色を変更 */
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: width 0.35s cubic-bezier(0.87, 0.24, 0.36, 1), height 0.35s cubic-bezier(0.87, 0.24, 0.36, 1);
  z-index: 1;
}

.c-btn-ver2__txt {
  margin-bottom: -1px;
  max-height: 100%;
  position: relative;
  z-index: 2;
  margin-left: 1rem;
}

.c-btn-ver2__blank,
.c-btn-ver2__dlArrow {
  display: inline-block;
  height: 10px;
  margin-right: -10px;
  position: relative;
  transition: background 0.2s 0.2s;
  z-index: 2;
}

.c-btn-ver2:hover .c-btn-ver2__inner {
  color: #fff;
}

.c-btn-ver2:hover .c-btn-ver2__circle>span {
  height: 1600px;
  width: 1600px;
}

/* ======================================================================

  ## c-btn-ver3

====================================================================== */

.c-btn-ver3 {
  border-radius: 42px;
  border: 1px solid global.$color-orange; /* 色を変更 */
  color: white;
  display: inline-flex;
  font-family: jaf-bernina-sans, Hiragino Kaku Gothic Pro, Meiryo, Noto Sans JP, sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  height: 55px;
  letter-spacing: 0.01em;
  line-height: 1.3;
  position: relative;
  text-align: center;
  width: 100%;
}

@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-btn-ver3 {
    width: 300px;
  }
}

.c-btn-ver3--sizeAuto {
  min-width: 300px;
  width: auto;
}

.c-btn-ver3.is-current .c-btn__inner {
  background-color: #333;
  color: #fff;
}

.c-btn-ver3__inner {
  align-items: center;
  background-color: white;
  border-radius: 42px;
  color: #333;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: color 0.2s 0.2s;
  width: 100%;
  z-index: 1;
}

.c-btn-ver3__inner--sizeAuto {
  padding: 0 42px;
}

.c-btn-ver3__circle {
  left: 24px;
  top: 24px;
}

.c-btn-ver3__circle,
.c-btn-ver3__circle>span {
  display: inline-block;
  height: 5px;
  position: absolute;
  width: 5px;
}

.c-btn-ver3__circle>span {
  background-color: global.$color-orange; /* 色を変更 */
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: width 0.35s cubic-bezier(0.87, 0.24, 0.36, 1), height 0.35s cubic-bezier(0.87, 0.24, 0.36, 1);
  z-index: 1;
}

.c-btn-ver3__txt {
  margin-bottom: -1px;
  max-height: 100%;
  position: relative;
  z-index: 2;
  margin-left: 1rem;
}

.c-btn-ver3__blank,
.c-btn-ver3__dlArrow {
  display: inline-block;
  height: 10px;
  margin-right: -10px;
  position: relative;
  transition: background 0.2s 0.2s;
  z-index: 2;
}

.c-btn-ver3:hover .c-btn-ver3__inner {
  color: #fff;
}

.c-btn-ver3:hover .c-btn-ver3__circle>span {
  height: 1600px;
  width: 1600px;
}
