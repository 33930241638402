/* ======================================================================

  # card-grid
  - component

====================================================================== */
@use '../../foundation/tool/global';

.c-card-grid {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.6rem
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-card-grid {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(3,1fr);
    -moz-column-gap: 2%;
    column-gap: 3rem;
    align-items: stretch
  }
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-card-grid.c-card-grid--row {
    grid-template-columns: 1fr;
    transform: translateX(6vw)
  }
}
@media print,
screen and (min-width:1200px) {
  .c-card-grid.c-card-grid--row {
    transform: translateX(8.958333335vw)
  }
}