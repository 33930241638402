/* ****************************************************************************************************

  # front-main-head
  - project

**************************************************************************************************** */
@use '../../foundation/tool/global';

.p-front-main-head{
  display:flex;
  flex-direction:column;
  text-align: center;
  margin-top:6rem;
  margin-bottom: 2rem;
  z-index: 3;
  position: relative;

}
.p-front-main-head__title{
  font-feature-settings:normal;
  display:inline-flex;
  font-family:游ゴシック体,YuGothic,游ゴシック,Yu Gothic,Hiragino Kaku Gothic Pro,Meiryo,Noto Sans JP,sans-serif;
  font-size:3.4rem;
  font-size:7vw;
  font-weight:700;
  letter-spacing:-.01em;
  line-height:1.49;
  position:relative
}
.p-front-main-head__title:after,.p-front-main-head__title:before{
  content:"";
  display:block;
  height:0;
  width:0
}
.p-front-main-head__title:before{
  margin-top:-.25em
}
.p-front-main-head__title:after{
  margin-bottom:-.25em
}
.p-front-main-head__title:first-line{
  margin-left:0
}
.p-front-main-head__titleLayerA{
  color:#333;
  opacity:0;
  transform:translate3d(0,15px,0);
  transition:opacity .65s cubic-bezier(.24,.13,.26,1.01),transform .9s cubic-bezier(.24,.13,.26,1.01)
}
@supports (clip-path:polygon(0 0,0 0,0 0)){
  .p-front-main-head__titleLayerA{
      color:global.$color-light-blue;
  }
}
.p-front-main-head__titleLayerAItem01{
  display:inline-flex
}
.p-front-main-head__titleLayerAItem02{
  display:inline-flex;
}
.p-front-main-head__titleLayerAItem03{
  display:inline-flex;
  margin-left:-.439238653vw
}
.p-front-main-head__titleLayerB{
  display:none;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none
}
@supports (clip-path:polygon(0 0,0 0,0 0)){
  .p-front-main-head__titleLayerB{
      clip-path:polygon(0 0,0 0,0 0);
      color:#333;
      display:block;
      left:0;
      position:absolute;
      top:0;
      transition:clip-path .65s cubic-bezier(.24,.13,.26,1.01) 1s
  }
}
.p-front-main-head__titleLayerBItem01{
  display:inline-flex
}
.p-front-main-head__titleLayerBItem02{
  display:inline-flex;
  margin-left:-.439238653vw
}
.p-front-main-head__subTitle{
  font-feature-settings:normal;
  color:#92c8c7;
  font-family:jaf-bernina-sans,Hiragino Kaku Gothic Pro,Meiryo,Noto Sans JP,sans-serif;
  font-size:1.2rem;
  font-size:3.2vw;
  font-weight:600;
  line-height:1.7;
  margin-bottom:2.6666666667vw;
  opacity:0;
  transform:translate3d(0,8px,0);
  transition:opacity .65s ease 1.5s,transform .65s ease 1.5s
}
.p-front-main-head__subTitle:after,.p-front-main-head__subTitle:before{
  content:"";
  display:block;
  height:0;
  width:0
}
.p-front-main-head__subTitle:before{
  margin-top:-.35em
}
.p-front-main-head__subTitle:after{
  margin-bottom:-.35em
}
.p-front-main-head.is-inView .p-front-main-head__titleLayerA{
  opacity:1;
  transform:translateZ(0)
}
.p-front-main-head.is-inView .p-front-main-head__titleLayerB{
  clip-path:polygon(0 0,200% 0,0 200%)
}
.p-front-main-head.is-inView .p-front-main-head__subTitle{
  opacity:1;
  transform:translateZ(0)
}

@media print, screen and (min-width: global.$breakpoint-min-md) {

  .p-front-main-head__title{
    font-size:3.2vw;
    line-height:1.35;
    margin: auto;
    width: 52vw;
  }
  .p-front-main-head__subTitle{
    font-size:1.4rem;
    font-size:1.4vw;
    margin-bottom:1.2445095168vw;
    margin-left:.439238653vw
  }
}