/* ****************************************************************************************************

  # txt-bold
  - utility

**************************************************************************************************** */
@use '../../foundation/tool/global';

.u-txt-bold {
  font-weight: global.$font-weight-bold !important;
}
