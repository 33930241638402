/* ****************************************************************************************************

  # hamburger
  - project

**************************************************************************************************** */
@use '../../foundation/tool/global';
// ```
// div.p-hamburger#js-gnav-trg
//   div.p-hamburger__container
//     span.p-hamburger__item
//     span.p-hamburger__item
//     span.p-hamburger__item
// ```

.p-hamburger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.7rem;
  height: 3.7rem;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  @include global.z-index(over-gnav);
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-hamburger {
    display: none;
  }
}

/* ======================================================================

  ## container

====================================================================== */
.p-hamburger__container {
  position: relative;
  display: block;
  width: 2.7rem;
  height: 2.3rem;
  margin: auto;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-hamburger__container {
    width: 3rem;
    height: 2.5rem;
  }
}

/* ======================================================================

  ## item

====================================================================== */
.p-hamburger__item {
  position: absolute;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  margin: auto;
  background-color: global.$color-black;
  transition: global.$transition-duration global.$transition-timing-function;
}

.p-hamburger__container > :nth-child(1) {
  top: 0;
}

.p-hamburger__container > :nth-child(2) {
  top: 0;
  bottom: 0;
  width: 100%;
}

.p-hamburger__container > :nth-child(3) {
  bottom: 0;
}

.p-hamburger.is-active .p-hamburger__container > :nth-child(1) {
  top: 48%;
  transform: rotate(45deg);
}

.p-hamburger.is-active .p-hamburger__container > :nth-child(2) {
  opacity: 0;
}

.p-hamburger.is-active .p-hamburger__container > :nth-child(3) {
  bottom: 47%;
  transform: rotate(-45deg);
}
