/* ****************************************************************************************************

  # divider
  - component

**************************************************************************************************** */
@use '../../foundation/tool/global';
// ```
// .c-divider
//   ...
// .c-divider
//   ...
// ```

$divider-gap: 3rem;
$divider-gap-mq-min-md: 9rem;
$divider-gap-sm: 1.4rem;
$divider-gap-sm-mq-min-md: 2.8rem;
$divider-gap-lg: 5rem;
$divider-gap-lg-mq-min-md: 8.6rem;

.c-divider + .c-divider {
  margin-top: $divider-gap;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-divider + .c-divider {
    margin-top: $divider-gap-mq-min-md;
  }
}

.c-divider + .c-divider.c-divider--gap_sm {
  margin-top: $divider-gap-sm;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-divider + .c-divider.c-divider--gap_sm {
    margin-top: $divider-gap-sm-mq-min-md;
  }
}

.c-divider + .c-divider.c-divider--gap_lg {
  margin-top: $divider-gap-lg;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-divider + .c-divider.c-divider--gap_lg {
    margin-top: $divider-gap-lg-mq-min-md;
  }
}
