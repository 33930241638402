/* ****************************************************************************************************

  # gnav
  - project

**************************************************************************************************** */
@use '../../foundation/tool/global';

.p-gnav {
  position: fixed;
  top: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  padding: (global.$header-height + 3.8rem) global.$content-side-gutter 5rem;
  overflow-y: auto;
  font-size: 1.4rem;
  background-color: global.$color-light-blue;
  transition: transform .1s;
  transform: translateX(100%);
  -webkit-overflow-scrolling: touch;
  @include global.z-index(gnav);
}

.p-gnav.is-active {
  height: 100vh;
  transition: transform .5s cubic-bezier(.165,.84,.44,1) .3s;
  transform: translateX(0%);
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-gnav {
    position: relative;
    width: auto;
    padding: 0;
    margin-right:14rem;
    overflow: visible;
    background-color: transparent;
    transform: translateX(0);
  }
}

/* ======================================================================

  ## list

====================================================================== */
.p-gnav__list {
  display: block;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-gnav__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

/* ======================================================================

  ## divider

====================================================================== */
.p-gnav__divider {
  position: relative;
  display: block;
  border-bottom:1px dashed #333;
  padding-bottom:1.6rem;
}

.p-gnav__divider + .p-gnav__divider {
  padding-top:1.6rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-gnav__divider {
    border-bottom: none;
    padding-bottom: 0;
  }
  .p-gnav__divider + .p-gnav__divider {
    margin-top: 0;
    margin-left: 1rem;
    padding-top: 0;
  }
}

/* ======================================================================

  ## item

====================================================================== */
.p-gnav__item {
  position: relative;
  display: block;
  padding: .2rem 1rem .2rem 0;
  font-size: global.$font-size-md;
  font-weight: global.$font-weight-bold;
  color: global.$color-txt;

  // &::after {
  //   position: absolute;
  //   top: 50%;
  //   right: 0;
  //   display: block;
  //   width: .4rem;
  //   height: .4rem;
  //   content: '';
  //   border-top: 1px solid global.$color-white;
  //   border-right: 1px solid global.$color-white;
  //   transform: translate(-12%, -50%) scale(1, .8) rotate(45deg);
  // }
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-gnav__item {
    padding: .4rem .8rem;
    font-size: global.$font-size-mq-min-md;
    color: global.$color-black;
    position: relative;

    &::after {
      content: none;
    }
  }
}

/* ======================================================================

  txt

====================================================================== */
.p-gnav__txt {
  line-height: 1.4;
}
// @media print, screen and (min-width: global.$breakpoint-min-md) {
//   .p-gnav__txt {
//   }
// }

/* ======================================================================

  gnav__item-child-wrap

====================================================================== */
.p-gnav__item-child-wrap {
  line-height: 1.4;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-gnav__item-child-wrap {
    opacity: 0;
    position: absolute;
    transition: .3s;
    top:4.6rem;
    left:50%;
    transform: translateX(-50%);
    background: global.$color-light-blue;
    padding: 1.2rem 2rem;
    width: 18rem;
    &::before{
      content: "";
      position: absolute;
      top:-2rem;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-bottom: 2rem solid global.$color-light-blue;
      border-left: 2rem solid transparent;
      border-right: 2rem solid transparent;
    }
  }
  .p-gnav__item-child-wrap.p-gnav__item-child-wrap--active {
    opacity: 1;
  }
}


/* ======================================================================

  gnav__item-child

====================================================================== */
.p-gnav__item-child {
  line-height: 2.2;
  color: global.$color-txt;
  &::before {
    content: ''; /* 疑似要素のコンテンツを空にする */
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 6px solid transparent; /* 上矢印のサイズや色を調整 */
    border-bottom: 6px solid transparent; /* 下矢印のサイズや色を調整 */
    border-left: 6px solid global.$color-blue; /* 左矢印のサイズや色を調整 */
    margin-right: 5px; /* 矢印とテキストの間の余白 */
  }
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-gnav__item-child {
    color: #333;
    display: block;
  }
}
