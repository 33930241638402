/* ****************************************************************************************************

  # color
  - foundation > variable > base

**************************************************************************************************** */

$color-white: #fff;
$color-black: #333;
$color-gray: gray !default;
$color-gray-light: lightgray !default;
$color-gray-med: darkgray !default;
$color-gray-dark: #464643 !default;

/* 赤
---------------------------------------------------------------------- */
$color-red: 	#CF5659 !default;

/* ピンク
---------------------------------------------------------------------- */
$color-pink: #d487bd !default;

/* 黄色
---------------------------------------------------------------------- */
$color-yellow: #e6c665 !default;

/* オレンジ
---------------------------------------------------------------------- */
$color-orange: #f39800 !default;

/* ベージュ
---------------------------------------------------------------------- */
$color-beige: beige !default;

/* 水色
---------------------------------------------------------------------- */
$color-light-blue: #e7f3f3 !default;

/* 青
---------------------------------------------------------------------- */
$color-blue: #92c8c7 !default;
$color-dark-blue: #2b9492 !default;
$color-little-dark-blue: #8bc5c5 !default;

/* 緑
---------------------------------------------------------------------- */
$color-green: #96c056 !default;

/* txt
---------------------------------------------------------------------- */
$color-txt: #555 !default;
$color-txt-dark: #333 !default;
$color-txt-light: lightgray !default;
$color-link: red !default;

/* border
---------------------------------------------------------------------- */
$color-border: gray !default;
$color-border-dark: dimgray !default;

/* input系列
---------------------------------------------------------------------- */
$color-input-bg: $color-gray-light !default;
