/* ======================================================================

  # card-grid
  - component

====================================================================== */
@use '../../foundation/tool/global';

.c-card-gkp__item{
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.6rem;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-card-gkp__item {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(2,1fr);
    -moz-column-gap: 2%;
    column-gap: 2%;
    align-items: stretch
  }
}

.c-card-gkp__item + .c-card-gkp__item{
  margin-top:3rem;
}


/* ======================================================================

  ## c-card-gkp__item--flow

====================================================================== */


@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-card-gkp__item--flow {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(2, 1fr);
    -moz-column-gap: 2%;
    column-gap: 2%;
    align-items: stretch;
    position: relative;
  }
  .c-card-gkp__item--flow:nth-child(even) {
    grid-template-columns: 1fr 1fr;
  }
  .c-card-gkp__item--flow:nth-child(even) > div:nth-child(1) {
    order: 2;
  }
  .c-card-gkp__item--flow:nth-child(even) > div:nth-child(2) {
    order: 1;
  }
  /* 画像が入っている箇所から斜め下に斜めの点線を追加 */
  .c-card-gkp__item--flow::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 50%; /* 点線の右側の位置調整 */
    transform: translateX(-50%) rotate(45deg); /* 点線を斜めに配置 */
    width: 2px;
    height: 100px; /* 点線の長さ */
    background-color: transparent;
    border-left: 2px dashed global.$color-dark-blue; /* 点線のスタイル */
    z-index: 3; /* 画像より背面に配置 */
  }
  /* 2番目の要素の点線を左から右に配置 */
  .c-card-gkp__item--flow:nth-child(even)::after {
    right: auto; /* 右側の位置をリセット */
    left: 50%; /* 点線の左側の位置調整 */
    transform: translateX(-50%) rotate(-45deg); /* 点線を斜めに配置 */
  }
  /* テキストを画像の上下の真ん中に配置 */
  .c-card-gkp__item--flow > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* 最後の要素の斜め線を非表示にする */
  .c-card-gkp__item--flow:last-child::after {
    display: none;
  }
}