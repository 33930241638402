/* ****************************************************************************************************

  # typography
  - foundation > variable > base

**************************************************************************************************** */

/* ======================================================================

  ## font-family

====================================================================== */
$font-family: 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', meiryo, sans-serif;
$font-family-serif: 'YuMincho', 'Yu Mincho', 'Hiragino Mincho ProN',
  'Hiragino Mincho Pro', serif;

/* ======================================================================

  ## 行間

====================================================================== */
$line-height: 1.75;

/* ======================================================================

  ## 太さ

====================================================================== */
$font-weight-normal: normal;
$font-weight-bold: bold;

/* ======================================================================

  ## 大きさ

====================================================================== */
$font-size: 1.4rem;
$font-size-mq-min-md: 1.5rem;
$font-size-sm: 1.2rem;
$font-size-sm-mq-min-md: 1.2rem;
$font-size-xs: 1.2rem;
$font-size-xs-mq-min-md: 1.2rem;
$font-size-md: 1.5rem;
$font-size-md-mq-min-md: 1.6rem;
$font-size-lg: 1.8rem;
$font-size-lg-mq-min-md: 2.2rem;
$font-size-xl: 2.2rem;
$font-size-xl-mq-min-md: 3.8rem;
$font-size-heading: 1.8rem;
$font-size-heading-mq-min-md: 1.5rem;
$font-size-title: 2.2rem;
$font-size-title-mq-min-md: 2.4rem;
