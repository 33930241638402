/* ****************************************************************************************************

  # home-kv-title
  - scope

**************************************************************************************************** */
@use '../../foundation/tool/global';

.s-home-kv-title {
  position: absolute;
  top: 50%;
  left: -2vw;
  transform: translateY(-50%);
  z-index: 4;
  opacity: 0; /* 初期状態は透明にする */
}

.s-home-kv-title.active {
  left: 4vw;
  opacity: 1; /* 不透明にする */
  transition: opacity 0.5s ease, left 1s ease; /* 透明度と左から右への移動のアニメーション */
}

/* ======================================================================

  ## catch

====================================================================== */
.s-home-kv-title__catch {
  font-size: 1.8rem;
  color: global.$color-white;
  font-weight: bold;
  letter-spacing: .05em;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .s-home-kv-title__catch {
    font-size: 2.2rem;
  }
}


/* ======================================================================

  ## item

====================================================================== */
.s-home-kv-title__item {
  width:100%;
  color: global.$color-white;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .s-home-kv-title__item {
    width:70rem;
  }
}
