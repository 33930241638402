/* ****************************************************************************************************

  # anchor-txt
  - utility

**************************************************************************************************** */
@use '../../foundation/tool/global';

.u-anchor-txt {
  color: global.$color-link;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
