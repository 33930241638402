/* ****************************************************************************************************

  # photo-btn
  category: project

**************************************************************************************************** */
@use '../../foundation/tool/global';

/* ======================================================================

  ## item

====================================================================== */
.p-photo-btn__item {
    position: relative;
    display: block;
  }
  
  /* ======================================================================
  
    ## head
  
  ====================================================================== */

  /* ======================================================================
  
    ## image
  
  ====================================================================== */
  .p-photo-btn__image {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2); /* 背景色と透明度の設定 */
        z-index: 2; /* 画像より手前に表示 */
        border-radius: 0 1rem 0 1rem;
    }
    @include global.fitted-image(
      $ratioWidth: 800,
      $ratioHeight: 534,
      $objectFitCover: true
    );
  }
  
  /* ### img
  ---------------------------------------------------------------------- */
  .p-photo-btn__image > img {
    display: block;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: "object-fit: cover;";
    border-radius: 0 1rem 0 1rem;
  }
  
  /* ======================================================================
  
    ## body
  
  ====================================================================== */
  .p-photo-btn__body{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 3;
    color:white;
    width: 100%;
    text-align: center;
  }
  
  /* ======================================================================
  
    ## date
  
  ====================================================================== */
  .p-photo-btn__icon {
    width:5rem;
    margin:auto;
  }
  
  /* ======================================================================
  
    ## title
  
  ====================================================================== */
  .p-photo-btn__title {
    font-size: 2.2rem;
    font-weight: global.$font-weight-bold;
  }
  
  .p-photo-btn__icon + .p-photo-btn__title {
    margin-top: .5rem;
  }
  @media print, screen and (min-width: global.$breakpoint-min-md) {
    .p-photo-btn__title {
      font-size: 2.8rem;
    }
  }
  
  /* ======================================================================
  
    ## txt
  
  ====================================================================== */
  .p-photo-btn__txt {
    font-size: global.$font-size;
    line-height: 1.5;
  }
  
  .p-photo-btn__title + .p-photo-btn__txt {
    margin-top: .8rem;
  }
  @media print, screen and (min-width: global.$breakpoint-min-md) {
    .p-photo-btn__txt {
      font-size: global.$font-size-mq-min-md;
    }
  
    .p-photo-btn__title + .p-photo-btn__txt {
      margin-top: .9rem;
    }
  }