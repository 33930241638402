/* ****************************************************************************************************

  # fixed-btn
  - scope

**************************************************************************************************** */
@use '../../foundation/tool/global';

.s-fixed-btn {
  position: absolute;
  top: 0;
  right: 6.5rem;
  bottom: auto;
  left: auto;
  width: 14rem;
  height: 6.2rem;
  background: global.$color-green;
  background: linear-gradient(to right, #2b9492, #92c8c7); /* グラデーション */
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .s-fixed-btn {
    right:0;
  }
}

.s-fixed-btn__item{
  
}

.s-fixed-btn__txt {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
}
