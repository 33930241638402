/* ****************************************************************************************************

  # home-kv
  - layout

**************************************************************************************************** */
@use '../foundation/tool/global';

.l-home-kv {
  position: relative;
  display: block;
  width: 100%;
  height: 100vw;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .l-home-kv {
    height: calc(100vh - 4vw);
  }
}


/* ======================================================================

  ## container

====================================================================== */
.l-home-kv__container {
  position: relative;
  z-index: 4;
  width: 100%;
  height: 100%;
}


/* ======================================================================

  ## image

====================================================================== */
.l-home-kv__image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: block;
  width: 100%;
  height: 100%;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    // background-color: #071505;
    // mix-blend-mode: overlay;
    opacity: .2;
  }
}

/* ### img
---------------------------------------------------------------------- */
.l-home-kv__image > img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: block;
  width: 100%;
  height: 100%;
  font-family: 'object-fit: cover;';
  object-fit: cover;
}

/* ======================================================================

  ## background-飾り

====================================================================== */

.l-home-kv__diagonal-blue-background{
  opacity: 80%;
  position: absolute;
  top: 0;
  left: -80%;
  width: 40%; 
  height: 100%;
  background-color: global.$color-blue; /* 青色の背景色 */
  transform: skewX(-20deg); /* 斜めに傾ける */
  z-index: 1; 
}
.l-home-kv__diagonal-blue-background2{
  opacity: 80%;
  position: absolute;
  top: 0;
  left: -80%;
  width: 40%; 
  height: 100%;
  background-color: global.$color-dark-blue; /* 青色の背景色 */
  transform: skewX(-20deg); /* 斜めに傾ける */
  z-index: 1;
}

.l-home-kv__diagonal-blue-background.active{
  opacity: 80%;
  position: absolute;
  top: 0;
  left: -25%;
  width: 40%; 
  height: 100%;
  background-color: global.$color-blue; /* 青色の背景色 */
  transform: skewX(-20deg); /* 斜めに傾ける */
  z-index: 1; 
  transition: left .8s ease; /* 左から右への移動*/
}
.l-home-kv__diagonal-blue-background2.active{
  opacity: 80%;
  position: absolute;
  top: 0;
  left: -42%;
  width: 40%; 
  height: 100%;
  background-color: global.$color-dark-blue; /* 青色の背景色 */
  transform: skewX(-20deg); /* 斜めに傾ける */
  z-index: 1;
  transition: left 0.2s ease; /* 左から右への移動 */
}