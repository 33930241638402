/* ****************************************************************************************************

  # clearfix
  - utility

**************************************************************************************************** */

.u-clearfix {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}
