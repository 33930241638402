/* ****************************************************************************************************

  # list
  - component

**************************************************************************************************** */
@use '../../foundation/tool/global';

.c-list {
  display: block;
  padding: 0 0 0 1.8rem;
  list-style-type: disc;
  font-size:1.4rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-list {
    font-size:1.6rem;
  }
}

.c-list .c-list {
  list-style-type: circle;
}

.c-list .c-list .c-list {
  list-style-type: disc;
}

.c-list__item > .c-list {
  margin-top: .5rem;
}

/* ======================================================================

  ## item

====================================================================== */
.c-list__item {
  position: relative;
  display: list-item;
  line-height: 1.5;
  list-style-type: inherit;
}

.c-list__item + .c-list__item {
  margin-top: .5rem;
}
