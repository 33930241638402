/* ****************************************************************************************************

  # padding
  - utility

**************************************************************************************************** */

/* ======================================================================

  上方向のパディング

====================================================================== */
@for $i from 0 through 10 {
  .u-pt#{$i} {
    padding-top: 1rem * $i !important;
  }
}

/* ======================================================================

  右方向のパディング

====================================================================== */
@for $i from 0 through 10 {
  .u-pr#{$i} {
    padding-right: 1rem * $i !important;
  }
}

/* ======================================================================

  下方向のパディング

====================================================================== */
@for $i from 0 through 10 {
  .u-pb#{$i} {
    padding-bottom: 1rem * $i !important;
  }
}

/* ======================================================================

  左方向のパディング

====================================================================== */
@for $i from 0 through 10 {
  .u-pl#{$i} {
    padding-left: 1rem * $i !important;
  }
}
