/* ======================================================================

  # c-card-value
  - component

====================================================================== */
@use '../../foundation/tool/global';

.p-card-value {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
  }
}

@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-card-value {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 1fr 3fr;
    -moz-column-gap: 0;
    column-gap: 0;
    align-items: stretch;
    > div {
        padding: 3rem;
      }
  }
}

.p-card-value + .p-card-value {
  margin-top: 3rem;
}

/* ======================================================================

  ## p-card-value__head

====================================================================== */
.p-card-value__head {
    background: global.$color-yellow;
    border-radius: 1rem 1rem 0 0;
    text-align: center;
    color:white;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
    .p-card-value__head {
    background: global.$color-yellow;
    border-radius: 1rem 0 0 1rem;
    }
}

// 水色
.p-card-value__head.p-card-value__head--blue {
    background: global.$color-dark-blue;
}

// 水色
.p-card-value__head.p-card-value__head--pink {
    background: global.$color-pink;
}


/* ======================================================================

  ## p-card-value__body

====================================================================== */
.p-card-value__body {
    background: white;
    border-radius: 0 0 1rem 1rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
    .p-card-value__body {
    border-radius: 0 1rem 1rem 0;
    }
}