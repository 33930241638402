/* ****************************************************************************************************

  # line-show
  - component

**************************************************************************************************** */
@use '../../foundation/tool/global';

.c-line-show.is-inView .c-line-show__item{
  opacity:1;
  transform:translateZ(0)
}
.c-line-show__item{
  display:block;
  opacity:0;
  transform:translate3d(0,50px,0);
  transition:opacity 1s cubic-bezier(.49,.1,.43,.99),transform 1s cubic-bezier(.17,.67,.32,.96)
}

@media print, screen and (min-width: global.$breakpoint-min-md) {
  .c-line-show__item{
    transition:opacity 1.2s cubic-bezier(.49,.1,.43,.99),transform 1.2s cubic-bezier(.17,.67,.32,.96)
  }
}