/* ****************************************************************************************************

  # bg
  - theme

**************************************************************************************************** */
@use '../../foundation/tool/global';

.t-bg-skelton {
  background-color: transparent !important;
}

.t-bg-white {
  background-color: global.$color-white;
}

.t-bg-light-gray{
  background: #fafafa;
}

.t-bg-light-blue{
  background: global.$color-light-blue;
}

.t-bg-light-blue-gradation{
  background: linear-gradient(to bottom, rgba(43, 148, 146, 1) 0%, rgba(146, 200, 199, 1) 100%);
}

.t-bg-gray {
  background-color: #f1f1f1;
}

.t-bg-stripe-blue {
  background-color: global.$color-white;
  background-image: repeating-linear-gradient(
    90deg,
    transparent,
    transparent 50%,
    global.$color-blue 50%,
    global.$color-blue 100%
  );
  background-size: 3.7rem 3.7rem;
}

.t-bg-dark-blue{
  background: global.$color-little-dark-blue;
}

.t-bg-gkp {
  position: relative;
  background: url(../img/subpage/gkp-back.jpg);
  background-size: cover;
  &::before {
    content: ""; /* 仮要素の追加 */
    position: absolute; /* 絶対位置指定 */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(43, 144, 142, 0.5) 80%, rgba(43, 144, 142, 0.5) 50%); /* 透明度を指定した網掛け */
  }
}

.t-bg-yellow{
  background: #fff8d5;
}