/* ****************************************************************************************************

  # profile
  - project

**************************************************************************************************** */
@use '../../foundation/tool/global';


//   .p-profile
//     .p-profile__divider
//       .p-profile__head
//         .p-profile__img
//         .p-profile__position
//         .p-profile__name
//       .p-profile__body
//         .p-profile__txt
//         .p-profile__title
//         .p-profile__txt


.p-profile__list {
  background-color: white;
  padding:2.5rem;
  margin-top:4rem;
  position: relative;
  border-radius: 0 1rem 0 1rem;
  font-size: 1.4rem;
  &::before{
    content: "";
    top: 0;
    left: 0;
    border-bottom: 3rem solid transparent;
    border-left: 3rem solid global.$color-blue; /* ラベルの色はここで変更 */
    position: absolute;
    z-index: 100;
  }
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-profile__list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
}

/* ======================================================================

  ## divider

====================================================================== */
.p-profile__divider {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.p-profile__divider + .p-profile__divider{
  margin-top:2rem;
}

/* ======================================================================

  ## head

====================================================================== */
.p-profile__head {
  margin-bottom: 2rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-profile__head {
    width:25%;
    text-align: center;
  }
}

/* ======================================================================

  ## img

====================================================================== */
.p-profile__img{
  border-radius: 50%;
  border:2px solid global.$color-blue;
  padding:1rem;
  >img{
    border-radius: 50%;
  }
}

.p-profile__img--full{
  width:100%;
  img{
    width:100%;
  }
}

/* ======================================================================

  ## profile__position

====================================================================== */
.p-profile__position{
  color:global.$color-dark-blue;
  font-size: global.$font-size-md;
  margin-top:3rem;
}

/* ======================================================================

  ## profile__name

====================================================================== */
.p-profile__name{
  font-size: global.$font-size-lg;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-profile__name{
    font-size: global.$font-size-lg-mq-min-md;
  }
}

/* ======================================================================

  ## body

====================================================================== */
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-profile__body {
    width:70%;
  }
}

/* ======================================================================

  ## txt

====================================================================== */
.p-profile__txt {
  font-size: global.$font-size-md;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-profile__txt {
    font-size: global.$font-size-md-mq-min-md;
  }
}

/* ======================================================================

  ## profile__title

====================================================================== */
.p-profile__title {
  font-size: global.$font-size-md;
  margin-top:2rem;
  padding-top:2rem;
  border-top:1px dashed global.$color-blue;
  color:global.$color-dark-blue;
  margin-bottom:1.4rem;
}

/* ======================================================================

  ## profile__title-solo

====================================================================== */
.p-profile__title-solo {
  font-size: global.$font-size-lg;
  color:global.$color-dark-blue;
  margin-bottom:1.4rem;
  text-align: center;
  margin: auto;
  margin-bottom:2rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-profile__title-solo {
    text-align: left;
  }
}

/* ======================================================================

  ## profile__member

====================================================================== */
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-profile__member{
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: 1fr;
  }
}

/* ======================================================================

  ## profile__member-head

====================================================================== */
.p-profile__member-head{
  margin-bottom: 2rem;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-profile__member-head{
    margin-bottom: 0;
  }
}

/* ======================================================================

  ## profile__member-body

====================================================================== */
.p-profile__member-body{
  padding-left: 0;
}
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .p-profile__member-body{
    padding-left: 2rem;
  }
}

/* ======================================================================

  ## profile__list

====================================================================== */
.p-profile__list{
  li{
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px dashed global.$color-gray;
    position: relative;
    padding-left: 3rem;
    &::after{
      content: "";
      width: 2rem;
      height: 2rem;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      background-image: url(../img/common/icon-human.svg);
    }
  }
}

/* ======================================================================

  ## profile__fukidashi

====================================================================== */

/* スマートフォン向けの吹き出し */
.profile__fukidashi {
  width: auto;
  margin-top: 1rem;
  border-radius: 1rem;
  background-color: #ffffff;
  padding: 2rem;
  position: relative;
}

.profile__fukidashi::before {
  position: absolute;
  top: -15px;
  left: 50%; /* 左右の中央に配置 */
  transform: translateX(-50%); /* 水平方向に中央揃え */
  width: 30px;
  height: 15px;
  background-color: #ffffff;
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
  content: '';
}

/* パソコン向けの吹き出し */
@media print, screen and (min-width: global.$breakpoint-min-md) {
  .profile__fukidashi::before {
    position: absolute;
    top: 1rem;
    left: -0.4rem;
    width: 15px;
    height: 30px;
    background-color: white;
    clip-path: polygon(0 50%, 100% 0, 100% 100%);
    content: '';
  }
}

