/* ****************************************************************************************************

  # liquid-image
  - component

**************************************************************************************************** */

.c-liquid-img {
  display: block;
  width: 100%;
  max-width: none;
  height: auto;
}
